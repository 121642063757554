import "./App.css";

function App() {
  return (
    <div className="App">
      <div className="container">
        <div className="row">
          <div className="col-xl text-center">
            <h1>Squad Clips</h1>
          </div>
        </div>
        <div className="row">
          <h3>mduke012</h3>
        </div>
        <div className="row">
          <h3>MooseMullet</h3>
        </div>
        <div className="row">
          <h3>WittyB14</h3>
          <div className="col-md-4">
            <video className="video" controls>
              <source src="/video/crazy car_optimized.mp4" type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </div>
        </div>
        <div className="row">
          <h3>Zyro3</h3>
          <div className="col-md-4">
            <video className="video" controls>
              <source src="/video/Dosie Doe_optimized.m4v" type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
